// import Validator from '@/validators'
import { isNumber } from '@/utils/validate'

export const baseFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'cardBin',
      type: 'input',
      label: 'card.card-bin',
      otherOptions: {
        maxlength: 20
      }
    },
    {
      field: 'bankName',
      type: 'input',
      label: 'card.bank-name',
      otherOptions: {
        maxlength: 100
      }
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    bankName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    cardBin: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: isNumber, trigger: 'change' },
      {
        min: 6,
        max: 10,
        message: {
          locale: 'general.length-warnning-message',
          range: {
            min: 6,
            max: 10
          }
        },
        trigger: 'change'
      }
    ]
  }
}
